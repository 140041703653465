import { H } from "@jfrk/react-heading-levels";
import { withComponentDefaults } from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import {
  getFilteredInvoices,
  getInvoicesYear,
} from "../hooks/useFilteredInvoices";
import useInvoicesSelectStyles from "../hooks/useInvoicesSelectStyles";

import Invoice from "./Invoice";
import * as defaultStyles from "./Invoices.module.css";

export default withComponentDefaults(Invoices, "invoices");

Invoices.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      dueDate: PropTypes.string,
      ocr: PropTypes.string,
      toPay: PropTypes.string,
      leftToPay: PropTypes.string,
      downloadLink: PropTypes.string,
    }),
  ).isRequired,
  currentBalance: PropTypes.string,
};

function Invoices({
  className,
  styles = defaultStyles,
  invoices,
  currentBalance,
  ...restProps
}) {
  const { t } = useTranslation();

  const [currentInvoice] = getFilteredInvoices("=", invoices);
  const previousInvoices = getFilteredInvoices("<", invoices);
  const previousInvoiceYears = getInvoicesYear(invoices);
  const [selectedYear, setSelectedYear] = useState(previousInvoiceYears[0]);

  const invoicesSelectStyles = useInvoicesSelectStyles();

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      {currentBalance && (
        <p className={clsx(styles.currentBalance)}>
          {t("currentBalanceLabel")} {currentBalance}
        </p>
      )}

      {currentInvoice && (
        <div className={clsx(styles.currentInvoice)}>
          <Invoice item={currentInvoice} />
        </div>
      )}

      <div className={clsx(styles.previousInvoices)}>
        <div className={clsx(styles.filter)}>
          <label htmlFor="year-selector" className={clsx(styles.label)}>
            {t("selectInvoiceYearLabel")}
          </label>
          <Select
            inputId="year-selector"
            className={styles.select}
            styles={invoicesSelectStyles}
            value={selectedYear}
            isSearchable={false}
            onChange={(selectedYear) => {
              setSelectedYear(selectedYear);
            }}
            options={previousInvoiceYears}
          />
        </div>
        <div className={clsx(styles.results)}>
          <div className={clsx(styles.resultsHeader)}>
            <H className={clsx(styles.resultsTitle)}>
              {t("invoices")} {selectedYear.value}
            </H>
          </div>
          <div className={clsx(styles.resultsContent)}>
            {previousInvoices
              ?.filter((invoice) => invoice.year === selectedYear.value)
              .map((invoice, index) => {
                return <Invoice item={invoice} key={index} />;
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
