/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { Icon } from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";

import * as defaultStyles from "./Alert.module.css";

Alert.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  msg: PropTypes.string,
  status: PropTypes.string,
};

function getBGColor(status, styles) {
  switch (status) {
    case "error":
      return styles.error;
    case "warning":
      return styles.warning;
    case "success":
      return styles.success;
    default:
      return styles.info;
  }
}

function getTextColor(status, styles) {
  switch (status) {
    case "error":
      return styles.textError;
    case "warning":
      return styles.textWarning;
    case "success":
      return styles.textSuccess;
    default:
      return styles.textInfo;
  }
}

function getIconColor(status) {
  switch (status) {
    case "error":
      return "#ef5350";
    case "warning":
      return "#ff9800";
    case "success":
      return "#4caf50";
    default:
      return "#03a9f4";
  }
}

export default function Alert({
  className,
  msg,
  status = "info",
  styles = defaultStyles,
}) {
  if (!msg) {
    return null;
  }
  return (
    <div
      className={clsx(styles.component, getBGColor(status, styles), className)}
      aria-live="polite"
    >
      <Icon
        name={status === "success" ? "check" : "info"}
        color={getIconColor(status)}
        className={styles.icon}
        css={css`
          flex-shrink: 0;
          align-self: start;
        `}
      />
      <p className={getTextColor(status, styles)}>{msg}</p>
    </div>
  );
}
