import "../foundation/fonts.module.css";
import "../foundation/typography.module.css";

import "./Box.props.css";
import "./DashboardMenu.props.css";
import "./Form.props.css";
import "./Header.props.css";
import "./NoticeModule.props.css";
import "./global.props.css";
import "./Section.props.css";
import "./TableModule.props.css";

export default {
  baseFontFamily: "Open Sans, system-ui, sans-serif",
  layoutMargins: "clamp(1.05rem, calc(0.84rem + 1.07vw), 1.8rem)",
  layoutPadding: "var(--layout-margins) 0",
  loginPageBackground:
    "linear-gradient(-45deg, rgba(250,250,255,1) 0%, rgba(250,250,255,1) 56%, rgba(255,255,255,1) 58%)",
  loginBoxWidth: "29rem",
  loginBoxDistance: "1rem",
  loginBoxSpacing: "clamp(2.00rem, 1.61rem + 1.95vw, 3.00rem)",
  loginBoxRadii: "0.25rem",
  loginBoxBorder: "1px solid #E0EBEF",
  loginBoxShadow:
    "0 15px 35px 0 rgb(60 66 87 / 8%), 0 5px 15px 0 rgb(0 0 0 / 12%)",
  loginBoxBackground: "#fff",
  loginBoxGap: "clamp(1.00rem, 0.80rem + 0.98vw, 1.50rem)",
  loginBoxLogoWidth: "50%",
  loginBoxHeadlineSize: "var(--font-size-h1)",
  loginBoxHeadlineAlign: "center",
  loginBoxHeadlineColor: "rgba(0, 0, 0, 0.8)",
  loginBoxDescriptionSize: "1rem",
  loginBoxDescriptionAlign: "center",
  loginBoxDescriptionWeight: "400",
  loginBoxDescriptionLetterSpacing: "inherit",
  loginBoxDescriptionLineHeight: "1.5",
  loginBoxDescriptionColor: "rgba(0, 0, 0, 0.8)",
  loginBoxAlertSize: "0.875rem",
  loginBoxAlertAlign: "left",
  loginBoxAlertWeight: "400",
  loginBoxAlertLetterSpacing: "inherit",
  loginBoxAlertLineHeight: "1.5",
  loginBoxAlertColor: "var(--color-muted-text)",
  loginNavDistance: "1rem",
  loginNavAlign: "center",
  loginNavGap: "0.5rem",
  loginBoxButtonGap: "1.5rem",
  loginBoxButtonIconSize: "2em",
  loginBoxButtonBackground:
    "linear-gradient(0deg, rgba(6,54,101,1) 0%, rgba(27,84,141,1) 100%);",
  loginBoxButtonColor: "#fff",
  loginBoxButtonPadding: "1rem",
  loginBoxButtonShadow:
    "0 15px 35px 0 rgb(60 66 87 / 8%), 0 5px 15px 0 rgb(0 0 0 / 12%)",
  loginBoxButtonFontSize: "1rem",
  loginBoxButtonHoverBackground: "#103B66",
  loginBoxButtonHoverBoxShadow:
    "0 15px 35px 0 rgb(60 66 87 / 4%), 0 5px 15px 0 rgb(0 0 0 / 8%)",
  loginBoxButtonFocusBoxShadow:
    "0 15px 35px 0 rgb(60 66 87 / 14%), 0 5px 15px 0 rgb(0 0 0 / 16%), 0 0 0 3px blue",
  headerFlyOutToggleBackgroundColor: "#fff",
  headerFlyOutToggleColor: "#1b548d",
};
