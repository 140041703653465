import { Button, Icon } from "@whitespace/components";
import userContext from "@whitespace/gatsby-theme-smorgasbord/src/contexts/userContext";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useDialogContext } from "../../../contexts/dialogContext";
import municipalityContext from "../../../municipalityContext";

import * as defaultStyles from "./LoginDialogButtons.module.css";

LoginDialogButtons.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function LoginDialogButtons({ styles = defaultStyles }) {
  const { loginURL } = useContext(userContext);
  const { selectedMunicipality } = useContext(municipalityContext);

  let url = new URL(loginURL, window.location.origin);
  url.searchParams.set("returnTo", window.location.href);
  url.searchParams.set("municipality", selectedMunicipality?.id);
  const { dispatch } = useDialogContext();
  const { t } = useTranslation();

  return (
    <>
      <Button
        to={url.toString()}
        className={clsx(styles.bankidButton)}
        type="external"
      >
        {t("loginWithBankId")}
      </Button>
      {selectedMunicipality?.id !== "astorp" && (
        <Button
          className={styles.kundnrButton}
          onClick={() => dispatch({ action: "SHOW_CUSTOMER_NUMBER_LOGIN" })}
        >
          {t("loginWithCustomerNumber")}
        </Button>
      )}
    </>
  );
}
